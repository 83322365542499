import React from 'react';
import bgVideo from '../bgVideo.mp4';
import { Link } from 'react-router-dom';
import Logo from '../logov1WhiteT.png';

const AboutWaitlist = () => {
    return (
        <div className="no-select bg-stone-900 w-screen min-h-screen p-8 m-auto flex flex-col items-center text-center text-white">
            <video autoPlay loop muted className="fixed inset-0 w-full h-full object-cover z-0 opacity-10">
                <source src={bgVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <Link to="/" className="top-4 left-4 z-50" title="Home">
                <img src={Logo} alt="Luxos Logo" className="w-20" />
            </Link>

            <h1 className="text-4xl font-bold mb-8 z-50">About the Luxos Waitlist</h1>

            <div className="max-w-2xl text-left z-50">
                <p className="text-lg mb-6">
                    Joining the Luxos waitlist means you’re at the forefront of premium fashion.
                    With our exclusive store opening just around the corner, we’re inviting you to
                    become part of an elite group of members who receive special benefits and early
                    access. By entering your email, you’ll be the first to know when our store launches
                    and enjoy exclusive perks, including a <strong>free Luxos watch</strong>!
                </p>

                <h2 className="text-2xl font-semibold mt-8 mb-4">Exclusive Perks for Waitlist Members</h2>

                <ul className="text-lg text-left space-y-4 mb-8">
                    <li>
                        <strong>Free Luxos Watch on Launch:</strong> As a thank-you for joining the waitlist, 
                        you’ll receive a free Luxos watch on your first purchase when our store opens.
                    </li>
                    <li>
                        <strong>Early Access:</strong> Get notified the moment we launch so you can browse,
                        shop, and experience our curated collections before anyone else.
                    </li>
                    <li>
                        <strong>Exclusive Discounts:</strong> Enjoy ongoing discounts as soon as we open,
                        with preferred pricing available only to waitlist members. Stay in style with
                        Luxos while saving on every new collection.
                    </li>
                    <li>
                        <strong>Premium Buyer Status:</strong> As a waitlist member, you’re granted
                        Premium Buyer status in our store. This level provides access to limited-edition
                        items, early sale announcements, and unique perks designed exclusively for our
                        top customers.
                    </li>
                    <li>
                        <strong>Promotional Offers and Style News:</strong> By entering your email, you
                        allow us to keep you updated with the latest Luxos news. You’ll receive updates on
                        our newest collections, exclusive offers, and styling tips to inspire your
                        wardrobe.
                    </li>
                </ul>

                <h2 className="text-2xl font-semibold mt-8 mb-4">Important Notice on Communications</h2>
                <p className="text-lg mb-10">
                    By entering your email, you agree to receive promotional emails from Luxos. We’re
                    committed to keeping you informed and inspired with relevant updates, offers, and
                    news we believe you’ll enjoy. You can unsubscribe at any time, though you may miss
                    out on exclusive perks and early access to Luxos products.
                </p>
            </div>
            
            <footer className="mt-8 text-sm text-gray-400 z-50">
                &copy; {new Date().getFullYear()} Luxos. All rights reserved.
            </footer>
        </div>
    );
};

export default AboutWaitlist;
