import { useState, useEffect, useRef } from 'react';
import logo from '../logo.png';
import bgVideo from '../bgVideo.mp4';
import '../App.css';
import { Link } from 'react-router-dom';

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const cursorRef = useRef(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setEmail('');
    setErrorMessage('');
    setSuccessMessage('');
  };

  const handleEmailChange = (e) => setEmail(e.target.value);

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle email submission
  const handleEmailSubmit = async () => {
    if (!isValidEmail(email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    try {
      const response = await fetch('https://luxos.store.sonicarts.xyz/API/subscribeWaitlist/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSuccessMessage('Thank you for joining the waitlist!');
        setEmail('');
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      setErrorMessage('Failed to connect. Please try again later.');
    }
  };

  useEffect(() => {
    const cursor = cursorRef.current;

    const handleMouseMove = (e) => {
      cursor.style.left = `${e.pageX}px`;
      cursor.style.top = `${e.pageY}px`;

      const elementUnderCursor = document.elementFromPoint(e.pageX, e.pageY);
      const backgroundColor = window.getComputedStyle(elementUnderCursor).backgroundColor;

      const isLightBackground = isLightOrDark(backgroundColor);
      cursor.classList.toggle('cursor-light', isLightBackground);
      cursor.classList.toggle('cursor-dark', !isLightBackground);
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const isLightOrDark = (color) => {
    const rgb = color.match(/\d+/g);
    const r = parseInt(rgb[0]);
    const g = parseInt(rgb[1]);
    const b = parseInt(rgb[2]);

    const brightness = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return brightness <= 128;
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-black p-5 text-center relative custom-cursor no-select">
      <video autoPlay loop muted className="absolute inset-0 w-full h-full object-cover z-0 opacity-80">
        <source src={bgVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="absolute inset-0 bg-black opacity-60 z-1"></div>

      <div className="relative z-10 text-center text-white space-y-4 animate-fade-in">
        <img src={logo} alt="Luxos Logo" className="w-36 mx-auto mb-5 animate-glow" />
        <h1 className="text-5xl font-light tracking-wide mb-2">Luxos is coming...</h1>
        <p className="text-lg mb-8">Be the first to experience the art of <strong>luxury</strong> and win a <strong>free luxos watch</strong> </p>

        <button
          onClick={openModal}
          className="bg-gradient-to-r from-gray-300 via-gray-100 cursor-none to-gray-300 text-black text-lg font-semibold py-3 px-10 rounded-full shadow-lg hover:bg-gray-100 transition duration-300 ease-in-out transform hover:scale-105"
        >
          Join the Waitlist
        </button>
        <Link to="/about">
          <p className="text-sm text-gray-300 mt-2 hover:underline cursor-none">About the waitlist</p>
        </Link>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-20">
          <div className="bg-gray-50 rounded-lg p-8 max-w-md w-full text-center shadow-2xl relative animate-fade-in">
            <h2 className="text-3xl font-semibold mb-4 text-gray-800">Get Notified First!</h2>
            <p className="text-gray-600 mb-6">Enter your email to join the exclusive waitlist.</p>

            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Your email address"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500 mb-4 cursor-none"
            />

            {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
            {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}

            <button
              onClick={handleEmailSubmit}
              className="bg-black text-white py-2 px-8 rounded-full hover:bg-gray-900 transition duration-300 cursor-none"
            >
              Join Now
            </button>

            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-none"
            >
              &times;
            </button>
          </div>
        </div>
      )}

      <div ref={cursorRef} className="cursor-circle shadow-lg"></div>
    </div>
  );
}

export default App;
